
import { defineComponent, ref, computed, watch } from 'vue'
import { useModelWrapper } from '@/utils/modelWrapper'
import { RuleQuery, Rule } from './querybuilder'

interface Props {
  query: {
    rule?: string,
    operator?: string,
    operand?: string,
    value?: any
  },
  rules: Rule[],
  ruleTypes: any,
  index: number,
  depth: number
}

export default defineComponent({
  model: {
    prop: 'query',
    event: 'update:query'
  },
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array,
      default: () => []
    },
    ruleTypes: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      required: true
    },
    depth: {
      type: Number,
      required: true
    }
  },
  setup(props: Props, { emit }) {
    const internalQuery = useModelWrapper(props, emit, 'query')
    watch(() => internalQuery.value.value, (inputValue) => {
      if (inputValue) {
        emit('rule-update')
      }
    })
    const initialRule = props.rules.find(rule => rule.id === props.query.rule)
    const selectedRule = ref(initialRule ? initialRule : props.rules[0])
    const ruleType = ref(props.ruleTypes[selectedRule.value.type])
    watch(selectedRule, (newValue) => {
      ruleType.value = props.ruleTypes[selectedRule.value.type]
      // Reset all operand, operator and value when selectedRule changes
      internalQuery.value = {
        rule: selectedRule.value.id,
        operand: ruleType.operands?.[0],
        operator: ruleType.operators?.[0],
        value: newValue.type === 'boolean' ? false : undefined
      }
    })

    const isCustomComponent = computed(() => selectedRule.value.type === 'custom-component')
    const selectOptions = computed(() => {
      if (!selectedRule.value.choices) {
        return {}
      }
      // Nest items to support <optgroup> if the rule's choices have
      // defined groups. Otherwise just return a single-level array
      return selectedRule.value.choices.reduce(function(groups: any[], item: any, index: number) {
        let key = item['group'];
        if (typeof key !== 'undefined') {
          groups[key] = groups[key] || [];
          groups[key].push(item);
        } else {
          groups[index] = item;
        }
        return groups;
      }, {});
    })

    const hasOptionGroups = computed(() => {
      return selectOptions.value.length && Array.isArray(selectOptions.value[0])
    })

    const remove = () => {
      emit('child-deletion-requested', props.index)
    }

    return {
      internalQuery,
      selectedRule,
      ruleType,
      isCustomComponent,
      selectOptions,
      remove,
    }
  }
})
